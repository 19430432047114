import React from 'react'
import ReactDOM from 'react-dom'
import { Copyright, Link, Portal } from 'react-portal'
import steps from './steps.json'
import imgSrcLogin from './assets/images/login.jpg'
import imgSrcLogo from './assets/images/logo.svg'
import imgSrcTour from './assets/images/tour.jpg'
import './assets/scss/main.scss'

const Footer = () => (
  <>
    <p>
      Built through a collaborative investment in Translational Infrastructure
      by The Ohio State University{' '}
      <Link url='https://ctsi.osu.edu/'>
        Clinical and Translational Science Institute (CTSI)
      </Link>{' '}
      at <Link url='https://osu.edu'>The Ohio State University</Link> (National
      Center for Advancing Translational Sciences, Grant UM1TR004548) and{' '}
      <Link url='https://medicine.osu.edu/research/information-technology'>
        The Research Information Technology ({'{rit}'})
      </Link>{' '}
      department within the{' '}
      <Link url='https://medicine.osu.edu/'>College of Medicine</Link> to
      provide world-class infrastructure to advance biomedical research in the
      health sciences colleges.
    </p>
    <p>
      <Copyright startYear={2020} />
      {' • '}
      <Link url='https://it.osu.edu/privacy'>Privacy Policy</Link>
    </p>
  </>
)

ReactDOM.render(
  <Portal
    categoryRenderer={() => null}
    footerRenderer={Footer}
    {...{
      applicationName: '{RIT}',
      imgSrcLogin,
      imgAltLogin: '',
      imgSrcLogo,
      imgAltLogo:
        'The Research Information Technology (RIT) department within the College of Medicine',
      imgSrcTour,
      steps
    }}
  />,
  document.getElementById('root')
)
